
import {
  File, Image, Option, Reference,
} from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import IStageContent from '../../../shared/fsxa/interfaces/stage/IStageContent'
import { mapImage } from '../../../shared/general/services/ImageService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { buildPlayerElements, getVideoFormat } from '../../../shared/general/services/VideoUrlService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'

interface ISlideData {
  st_headline ?: string
  st_alt_text ?: string
  st_image ?: Image
  st_image_decorative ?: boolean
  st_links ?: {
    data ?: {
      lt_link ?: Image | File | Reference | string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
    }
  }[]
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_slide_text ?: string
  st_toggle_video ?: boolean
  st_video_format ?: Option
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
}

interface IPayload {
  st_slides ?: TDataEntry<ISlideData>[]
  st_design ?: boolean
}

@Component({
  name: 'StCarousel',
  components: {
    BaseGridLayout,
    Carousel: () => import('../../carousel/Carousel.vue'),
  },
})
export default class StCarousel extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get slides () : IStageContent[] {
    return normalizeDataEntries(this.payload.st_slides).map((data : ISlideData) => ({
      links: this.getLinks(data),
      headline: data?.st_headline,
      text: data?.st_slide_text || '',
      image: mapImage(data.st_image, data.st_alt_text, data.st_alt_text, data.st_image_decorative),
      video: {
        id: data?.st_video_id || '',
        format: getVideoFormat(data?.st_video_format?.key),
        htmlPlayerElements: buildPlayerElements({
          mp4: data?.st_mp4,
          webm: data?.st_webm,
          ogv: data?.st_ogv,
        }),
        privacyHash: data?.st_vimeo_privacy_hash,
      },
    })) || []
  }

  private getLinks (buttons ?: ISlideData) : ILink[] {
    return buttons?.st_links?.map<ILink>((link) => getLinkObject(link, this.getUrlByPageId)) || []
  }
}
